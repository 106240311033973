ul.menu-list {
   margin-block: 2rem;
}
ul.menu-list li {
   margin-bottom: 3.5rem;
}

ul.menu-list img {
   display: none;
}

ul.menu-list li h3 {
   display: grid;
   grid-template-columns: 1fr max-content;
   align-items: center;
   gap: 1rem;
   padding-bottom: .8rem;
   margin-bottom: .8rem;
   border-bottom: .1rem dashed rgb( var(--primary) );
   text-transform: uppercase;
   grid-area: name;
}

ul.menu-list li .formula {
   font-style: italic;
   font-weight: 300;
   letter-spacing: .1rem;
   grid-area: formula;
}
ul.menu-list li.subcategory {
   text-align: center;
}
ul.menu-list li h2.badge {
   text-transform: uppercase;
   font-size: 1.15rem;
}

@media (min-width: 72em) {
   ul.menu-list li.photo {
      display: grid;
      grid-template-columns: 8rem 1fr;
      grid-template-areas: 'img name' 'img formula';
      column-gap: 1rem;
   }
   
   ul.menu-list img {
      display: block;
      aspect-ratio: 1;
      max-width: 8rem;
      border-radius: .8rem;
      grid-area: img;
   }
}