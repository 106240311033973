.gallery-container {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
}
.gallery-container > img {
   aspect-ratio: 1;
   object-fit: cover;
}
.gallery-container > img:hover {
   filter: brightness(.5);
   transition: var(--cubic-bez-4ms);
}

@media (max-width: 72em) {
   .gallery-container {
      grid-template-columns: 1fr;
   }
}