.tab-list {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   gap: 1rem;
}
.tab-list button {
   border-radius: 1rem;
   padding: .4rem 1.3rem;
   font-weight: 600;
   text-transform: uppercase;
   color: rgb( var(--primary) );
   background-color: transparent;
   border: .15rem solid transparent;
   transition: var(--cubic-bez-4ms);
}
.tab-list button.active {
   border-color: var(--white);
   color: var(--white);
}
.tab-list button:hover {
   border-color: rgba( var(--primary), .6);
   color: var(--white);
   background-color: rgba( var(--primary), .6);
}